import { useSession } from '@supabase/auth-helpers-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import LoginModal from '../../components/LoginModal/LoginModal';

export default function Login() {
  const router = useRouter();
  const session = useSession();

  useEffect(() => {
    if (session) {
      if (router.query.returnUrl) {
        router.push(router.query.returnUrl as string);
      } else {
        router.push('/');
      }
    }
  }, [session]);

  return (
    <LoginModal
      opened
      onClose={() => {
        if (session) {
          if (router.query.returnUrl) {
            router.push(router.query.returnUrl as string);
          } else {
            router.push('/');
          }
        } else {
          router.push('/');
        }
      }}
      isSignUp={false}
    />
  );
}
